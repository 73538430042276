.aboutboxContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.aboutimage {
  display: block;
  width: 100%;
  height: auto;
}

.aboutoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translateY(-100%);
  transition: 0.5s ease;
}

.aboutboxContainer:hover .aboutoverlay {
  transform: translateY(0px);
}

.abouttext {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.aboutCard {
  height: 300px;
  background-color: white;
  box-shadow: 3px 4px 7px 0px rgba(123, 123, 123, 0.1);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
