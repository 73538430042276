.inputBox {
  width: 100%;
  display: block;
  border-radius: 25px !important;
  padding-left: 50px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 900 !important;
  font-size: 20 !important;
  height: 70px;
  margin-left: 20px;
  margin-right: 20px;
}

.inputBox::placeholder {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 900 !important;
  font-size: 20 !important;
  color: #e1e1e1 !important;
}
