/* web */
@media only screen and (min-width: 1200px) {
  .bgcurve {
    background-image: url("../Images/mooncurve.png");
  }
}

/* mobile */
@media only screen and (max-width: 1200px) {
  .bgcurve {
    background-color: #004767;
  }
}

.bgGradientMain {
  background: rgb(5, 26, 37);
  background: linear-gradient(
    90deg,
    rgba(5, 26, 37, 1) 41%,
    rgba(7, 48, 70, 1) 94%
  );
}

.underlinecyan::after {
  content: "";
  height: 10px;
  background: #0087ce;
  border-radius: 5px;
  display: block;
}
.underlinecyan {
  display: inline-block;
}

.bgcurve {
  background-image: url("../Images/mooncurve.png");
}

.bglines {
  background-image: url("../Images/backgroundlines.png");
  background-repeat: no-repeat;
}

.shadowready {
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.24);
}

.morebox {
  font-size: 30px;
  position: relative;
  padding-right: 5px !important;
  padding-left: 5px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-100px);
  z-index: 4 !important;
}
